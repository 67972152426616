import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Routes } from "react-router-dom";
import { has } from 'ramda';
import './App.scss';
import './styles/loading.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ProgressBar from './features/progressBar/ProgressBar';
import { selectUser } from './features/user/userSlice';
import { initGTM } from "./utils/googleTagManager";
import { selectConfig } from './features/config/configSlice';
import ConfigState from "./interfaces/ConfigState";
import router from './app/router';
import Maintenance from './features/maintenance/Maintenance';
import { API_ACCESS_TOKENS, BASE_URLS, BRANDS } from './constants';
import './utils/i18n.js';


function App() {
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const { maintenanceMode } = useSelector<ConfigState, ConfigState>(selectConfig);

  useEffect(() => {
    const { hostname } = window.location;

    const isDev = hostname.includes('localhost');
    const isSid = hostname.includes('otrldev.uk');
    const isStage = hostname.includes('stage.otrl.io');

    let baseURL = null;
    let brand = null;
    let environment = null;

    const { REACT_APP_ENVIRONMENT, REACT_APP_BASE_URL, REACT_APP_BRAND } = process.env; 
    brand = REACT_APP_BRAND;

    if (isDev) {
      /*
         .env file should be created in root dir locally
         containing these values for local development
      */
      environment = REACT_APP_ENVIRONMENT;
      baseURL = REACT_APP_BASE_URL;
    } else if (isSid) {
      environment = 'sid';
      baseURL = `${hostname.split('.')[1]}.otrldev.uk`;
    } else if (isStage) {
      environment = 'stage';
      baseURL = 'stage.otrl.io';
    } else {
      // Assumed to be prod env
      environment = 'prod';
      baseURL = BASE_URLS[brand];
    } 

    const apiAccessToken = API_ACCESS_TOKENS[environment];

    let config = {
      apiAccessToken,
      baseURL,
      brand,
      environment,
      isDev,
      isSid,
      enableCardPayments: true,
      payPalEnabled: brand !== BRANDS.TB,
      maintenanceMode: false,
    };

    // @ts-ignore ignore global window params
    const featureFlagOverrides = window.featureFlagOverrides;

    if (featureFlagOverrides) {
      const {
        enableCardPayments,
        maintenanceMode,
        payPalEnabled,
      } = featureFlagOverrides;

      config = {
        ...config,
        ...(has('enableCardPayments', featureFlagOverrides) && { enableCardPayments }),
        ...(has('maintenanceMode', featureFlagOverrides) && { maintenanceMode }),
        ...(has('payPalEnabled', featureFlagOverrides) && { payPalEnabled }),
      };
    }

    dispatch({ type: 'config/initialise', payload: { config } });
    dispatch({ type: 'paypal/getClientToken' });
  }, [dispatch]);

  useEffect(() => {
    initGTM();
  }, []);

  return (
    <div className="digital-railcards-app">
      <Header user={user} />
      <div className="main-content">
        {maintenanceMode ? <Maintenance />: (<HashRouter>
          <ProgressBar />
          <Routes>
            {router}
          </Routes>
        </HashRouter>)}
      </div>
      <Footer />
    </div>
  );
}

export default App;
