import keyMirror from 'keymirror';

export const CustomerTitles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Mx'];

export const APP_PAGES = {
    HOMEPAGE: '/',
    RAILCARD_OPTIONS: '/railcard-options',
    CHOOSE_RAILCARD: '/choose-railcard',
    RAILCARD_FAQS: '/railcard-faqs',
    USER_INFO: '/user-info',
    RAILCARD_PHOTO: '/railcard-photo',
    PAYMENT: '/payment',
    NOT_FOUND: '*',
    PROOF_OF_AGE: '/proof-of-age',
    ORDER_SUMMARY: '/order-summary',
    RENEW: '/renew/:renewId',
}

export const RAILCARD_CODES = {
  '16-17': 'TSU',
  '16-25': 'YNG',
  '26-30':'TST',
  SENIOR: 'SRN',
  NETWORK: 'NEW',
  TWO_TOGETHER:'2TR',
  FAMILY_AND_FRIENDS: 'FAM' 
};

export const DOCUMENT_TYPES = {
  PASSPORT: 'passport',
  UK_DRIVERS_LICENSE: 'license',
  EEA_IDENTITY_CARD: 'id' 
};

export const API_DOCUMENT_TYPES = {
  'passport': 'Passport',
  'license': 'Driver License',
  'id': 'Identity Card' 
};

export const DOCUMENT_LENGTH = {
  'passport': 20,
  'license': 16,
  'id': 18
}

export const RAILCARD_EXPIRATIONS = {
  'ONE_YEAR': '1Y',
  'THREE_YEARS': '3Y'
}

export const NAME_MAX_LENGTH = 100;

export const FIRST_HOLDER = "firstHolder";
export const SECOND_HOLDER = "secondHolder";

export const customDropzoneErrorMessages = {
  "file-invalid-type": "File type must be: (JPEG, JPG, PNG)",
  "file-too-large": "File is larger than 4MB",
};

export const PaymentAuthCodes = {
  API_ERROR: 'E900',
  API_HTTP_ERROR: 'E901',
  BAD_PRICE: 'E003',
  BAD_TRANSACTION_REFERENCE: 'E008',
  BRAND_NOT_FOUND: 'A104',
  CANCELLED: 'C000',
  CARD_NOT_FOUND: 'E007',
  DECLINED_BY_UPSTREAM_PROCESSOR: 'D100',
  ENROLLED: 'S000',
  EXPIRED: 'E000',
  FRAUD: 'F001',
  MISSING_DELIVERY: 'E012',
  NO_RESULT: 'E006',
  OK: 'S001',
  ORDER_NOT_FOUND: 'E001',
  BAD_STATE: 'E002',
  PENDING: 'S002',
  PROCESSING: 'S003',
  REFUNDED: 'R000',
  REFUNDREJECTED: 'R001',
  REJECTED_BY_FRAUD: 'A136',
  REPLACED: 'R003',
  UNDER_REVIEW: 'F000',
  UNSUPPORTED_TYPE: 'E004',
  VOIDED: 'V000',
}

export const RAILCARD_STATUSES = {
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
  EXPIRED: 'Expired',
  FAILED: 'Failed',
  PENDING: 'Pending',
};

export const Pay360SessionState = keyMirror({
  CREATING: null,
  READY: null,
  INVALID: null,
  SUCCESS: null,
  FAILED: null,
  CANCELLED: null,
});

export const Pay360TransactionStates = keyMirror({
  TERMINATED: null,
  SUCCESS: null,
  STARTED: null,
  PROCESSING: null,
  FAILED: null,
  NOT_SUBMITTED: null,
});

export const API_ERROR_TYPES = {
  AUTH_ERROR: 'AuthenticationError',
  NOT_FOUND_ERROR: 'NotFoundError',
  PAYMENT_ERROR: 'PaymentError',
  DATABASE_ERROR: 'DatabaseError',
  VALIDATION_ERROR: "ValidationError"
};

export const COOKIE_NAME = 'Customer-Token';
export const DO_NOT_TRACK_COOKIE = 'DO_NOT_TRACK';

export const DATA_LAYER_NAME = 'dataLayer';

export const TRACKING_EVENTS = {
  ADDED_TO_BASKET: 'order:addToBasket',
  PHOTO_UPLOAD: 'order:photoUpload',
  PAID: 'order:paid',
  REMOVED_FROM_BASKET: 'order:removeFromBasket'
}

export const TRACKING_STEPS = {
  BASKET_EMPTY: {
    stepName: 'basket empty',
    stepNumber: 0
  },
  ORDER_STARTED: {
    stepName: 'order started',
    stepNumber: 1
  },
  PHOTO_UPLOAD: {
    stepNumber: 2
  },
  PAYMENT_SCREEN: {
    stepNumber: 3
  }
}

export const PRODUCT_CATEGORY = 'Digital Railcard';

export const CURRENCY_CODE = 'GBP';

export const PRODUCT_VARIANT = 'adult'

export const AUTH_ERRORS = {
  CUSTOMER_LOCKED_OUT: 'Customer reached maximum login attempts',
  CUSTOMER_AUTH_FAILED: 'Customer authentication failed',
};

export const AUTH_FRIENDLY_ERRORS = {
  CUSTOMER_LOCKED_OUT: 'Your account has been locked as you have entered an incorrect password a number of times in succession. In order to regain access to your account, please reset your password.',
  CUSTOMER_AUTH_FAILED: 'Login failed. Please check your details and try again',
}

export const RAILCARD_AGE_ERRORS = {
  AGE_INCOMPATIBLE: 'Your date of birth is not valid for this type of railcard',
  YNG_3_YEAR_MAX_AGE: 'Your age is not valid for the length of this railcard, but you can purchase a 1 year railcard'
};

export const MAX_FILE_UPLOAD_SIZE = 15728640; // 15MB in bytes

export const GOOGLE_GTMID = "GTM-5KMHVK";

export const PAY_360_SKIN_IDS = {
  "southeastern": {
    'dev': 80010966, // new SE dev skin
    'sid': 80010966, // new SE dev skin
    'stage': 80010966, // new SE dev skin
    'prod': 800018053 // Prod skin
  },
  "ticketyboo": {
    'dev': 80009908, // new TB dev skin
    'sid': 80009908, // new TB dev skin
    'stage': 80009908, // new TB dev skin
    'prod': 800018053 // Prod skin
  },
  "southern": {
    'dev': 80009858,
    'sid': 80009858,
    'stage': 80009858,
    'prod': 80009858 // Prod skin
  },
  "thameslink": {
    'dev': 80011943,
    'sid': 80011943,
    'stage': 80011943,
    'prod': 80011943 // Prod skin
  },
  "greatnorthern": {
    'dev': 80011947,
    'sid': 80011947,
    'stage': 80011947,
    'prod': 80011947 // Prod skin
  },
  "gatwick": {
    'dev': 80011951,
    'sid': 80011951,
    'stage': 80011951,
    'prod': 80011951 // Prod skin
  }
};

export const API_ACCESS_TOKENS =  {
  'dev': '8gh2w38ho43o83hw48o4hg8h4g8',
  'sid': '8gh2w38ho43o83hw48o4hg8h4g8',
  'stage': '86512cad76131783f5dae4346ddc3fb39f6f7c0f74b3039bff70ca4015ade034',
  'prod': 'otrl|a6af56be1691ac2929898c9f68c4b49a0a2d930849770dba976be5d792a',
};

export const BRANDS = {
  GN: 'greatnorthern',
  GX: 'gatwick',
  TB: 'ticketyboo',
  SE: 'southeastern',
  SN: 'southern',
  TL: 'thameslink',
};

export const BASE_URLS = {
  "greatnorthern": "greatnorthernrail.com",
  "gatwick": "gatwickexpress.com",
  "ticketyboo": "myticketyboo.com",
  "southeastern": "southeasternrailway.co.uk",
  "southern": "southernrailway.com",
  "thameslink": "thameslinkrailway.com",
}

export const BOOKING_ENGINE_URLS = {
  "greatnorthern": "https://ticket.greatnorthernrail.com/search",
  "gatwick": "https://ticket.gatwickexpress.com/search",
  "ticketyboo": "https://ticket.myticketyboo.com/search",
  "southeastern": "https://ticket.southeasternrailway.co.uk/search",
  "southern": "https://ticket.southernrailway.com/search",
  "thameslink": "https://ticket.thameslinkrailway.com/search",
}

export const BRAND_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/",
  "gatwick": "https://www.gatwickexpress.com/",
  "ticketyboo": "https://www.myticketyboo.com/",
  "southeastern": "https://www.southeasternrailway.co.uk/",
  "southern": "https://www.southernrailway.com/",
  "thameslink": "https://www.thameslinkrailway.com/",
}

export const GENERAL_TERMS_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/terms-of-use",
  "gatwick": "https://www.gatwickexpress.com/terms/terms-and-conditions",
  "ticketyboo": "https://myticketyboo.com/terms-and-conditions/",
  "southeastern": "https://www.southeasternrailway.co.uk/terms/terms-of-use",
  "southern": "https://www.southernrailway.com/terms/terms-and-conditions",
  "thameslink": "https://www.thameslinkrailway.com/terms-of-use/terms-and-conditions",
}

export const PRIVACY_POLICY_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/terms-of-use/privacy-policy",
  "gatwick": "https://www.gatwickexpress.com/terms/privacy-policy",
  "ticketyboo": "https://myticketyboo.com/privacy-policy/",
  "southeastern": "https://www.southeasternrailway.co.uk/privacy-notice-and-cookies/privacy-policy",
  "southern": "https://www.southernrailway.com/terms/privacy-policy",
  "thameslink": "https://www.thameslinkrailway.com/terms-of-use/privacy-policy",
}

export const RAILCARD_TERMS_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/tickets/discounts-and-railcards/railcards",
  "gatwick": "https://www.gatwickexpress.com/offers-and-discounts/discounts/railcards-and-student-discounts",
  "ticketyboo": "https://www.myticketyboo.com/railcards",
  "southeastern": "http://southeasternrailway.co.uk/railcards",
  "southern": "https://www.southernrailway.com/tickets/discounts-and-railcards/railcards",
  "thameslink": "https://www.thameslinkrailway.com/tickets/discounts-and-railcards/railcards",
}

export const FAQ_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/help-and-support/contact-us",
  "gatwick": "https://www.gatwickexpress.com/help-and-support/contact-us",
  "ticketyboo": "https://myticketyboo.com/faqs/",
  "southeastern": "https://www.southeasternrailway.co.uk/get-in-touch/contact-us",
  "southern": "https://www.southernrailway.com/help-and-support/contact-us",
  "thameslink": "https://www.thameslinkrailway.com/help-and-support/contact-us",
}

export const HELP_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/help-and-support/contact-us",
  "gatwick": "https://www.gatwickexpress.com/help-and-support/contact-us",
  "ticketyboo": "https://myticketyboo.com/faqs/",
  "southeastern": "https://www.southeasternrailway.co.uk/get-in-touch/contact-us",
  "southern": "https://www.southernrailway.com/help-and-support/contact-us",
  "thameslink": "https://www.thameslinkrailway.com/help-and-support/contact-us",
}

export const ACCESSIBILITY_URLS = {
  "greatnorthern": "https://www.greatnorthernrail.com/terms-of-use/site-accessibility",
  "gatwick": "https://www.gatwickexpress.com/terms/site-accessibility",
  "ticketyboo": "https://myticketyboo.com/terms-and-conditions/",
  "southeastern": "https://www.southeasternrailway.co.uk/website-accessibility-statement",
  "southern": "https://www.southernrailway.com/terms/site-accessibility",
  "thameslink": "https://www.thameslinkrailway.com/terms-of-use/site-accessibility",
}

export const STATIC_ASSETS = "public";
